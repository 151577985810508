// ================================================
// Code associated with SaveChapterDialog
// ================================================
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';                     //Development Package to validate prop types [Type Checking] passed down

// ==================== Modules =====================

// ==================== Components ==================

// ==================== Helpers =====================
import AlertType from '../../../../helpers/models/AlertType';

import post from '../../../../helpers/common/post';
import get from '../../../../helpers/common/get';
// ==================== MUI =========================
import { makeStyles } from '@material-ui/core/styles';  // withStyles can be used for classes and functional componenents but makeStyle is designed for new React with hooks

import Grid from '@material-ui/core/Grid';  // Normal Markup with MUI is layout -> Container -> Grid -> Paper etc...
import Box from '@material-ui/core/Box';    // Padding and margins

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';

// ==================== MUI Icons ====================
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';



import { Collapse, Typography } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { IconButton } from '@material-ui/core';


// ==================== MUI Icons ====================
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
// ==================== MUI Styles ===================

const useStyles = makeStyles((theme) =>    //Notice the hook useStyles
({
    root: {
        flexGrow: 1,     // CSS determined this way, flexbox properties
        height: '100%'
    },
    rootGrid: {
        height: '100%'
    }
}));


// ================= Static Variables ================

// ================= Static Functions ================


// ======================== React Modern | Functional Component ========================

const CreateChapterTemplateDialog = (props) => { // Notice the arrow function... regular function()  works too

    // Variables ===

    // Style variable declaration
    const classes = useStyles();

    // Declaration of Stateful Variables ===
    const { appState, setParentAlert, getParentData,
        createChapterTemplateDialog, setCreateChapterTemplateDialog,
        createChapterTemplateDialogExecuting, setCreateChapterTemplateDialogExecuting } = props;

    const [chapterName, setChapterName] = useState("");
    const [dataList, setDataList] = useState(null);
    const [alert, setAlert] = useState(new AlertType());
    const [isTemplates, setIsTemplates] = useState(true);
    const [currentCollection, setCurrentCollection] = useState("");
    const [selectedCollectionList, setSelectedCollectionList] = useState([]);
    const [collectionList, setCollectionList] = useState(null);

   
    // Functions ===

    // Insert the new booklet into the database upon creation
    const createChapterTemplate = useCallback(() => {
        if (chapterName !== "") {

            var HttpDataObject = {
                name: chapterName,
                surveyJSON: "",
                createdBy: appState._id,
                modifiedBy: appState._id
            };

            post("surveys/", appState.token, HttpDataObject, (error, response) => {
                if (error) {
                    setParentAlert(new AlertType('Unable create chapter. Please refresh and try again.', "error"));
                }
                else {
                    if (response.status === 201) {
                        getParentData();
                        setParentAlert(new AlertType('Successfully Created.', "success"));
                        //const _id = response.data.survey._id; The id to redirect to if you wish
                    }
                    else {
                        setParentAlert(new AlertType('Unable create chapter. Please refresh and try again.', "error"));
                    }
                }
            });

        }
        else {
            setParentAlert(new AlertType('Unable create chapter. Please refresh and try again.', "error"));
        }
    }, [appState, chapterName, setParentAlert]);

    const populateList = useCallback((data) => {
        let tempArray = [];

        if (isTemplates) {
            for (let index = 0; index < data.length; ++index) {
                tempArray.push(
                    {
                        _id: data[index]._id,
                        name: data[index].name,
                        surveyJSON: data[index].surveyJSON,
                        createdAt: data[index].createdAt,
                        createdBy: data[index].createdBy,
                        updatedAt: data[index].updatedAt,
                        modifiedBy: data[index].modifiedBy
                    }
                );
            }
        }
        else {
            for (let index = 0; index < data.length; ++index) {
                tempArray.push(
                    {
                        _id: data[index]._id,
                        surveyTemplate: data[index].surveyTemplate,
                        memberCollection: data[index].memberCollection || "",
                        member: data[index].member,
                        responseJSON: data[index].responseJSON,
                        completeness: data[index].completeness,
                        createdAt: data[index].createdAt,
                        createdBy: data[index].createdBy,
                        updatedAt: data[index].updatedAt,
                        modifiedBy: data[index].modifiedBy
                    }
                );
            }
        }

         setDataList([...tempArray]);
        // setSearchFilteredDataList([...tempArray]);
        // setSelectedDataItemsList([]);

    }, [isTemplates]);

    const getTemplates = useCallback(() => {
        setDataList(null);

        get("surveys/", appState.token, (err, res) => {
            if (err) {
                setAlert(new AlertType('Unable to retrieve templates. Please refresh and try again.', "error"));
            } else {
                if (res.status === 200) {
                    setCollectionList(res.data.surveyList); // Set collectionList with API data
                    populateList(res.data.surveyList);
                } else {
                    setAlert(new AlertType('Unable to retrieve templates. Please refresh and try again.', "error"));
                }
            }
        });
    }, [populateList, appState.token]);

    useEffect(() => {
        getTemplates();
    }, [getTemplates]);

    const closeHandler = useCallback(() => {
        setCreateChapterTemplateDialog(false);
        setChapterName("");
    }, [setCreateChapterTemplateDialog]);


    const createHandler = useCallback(() => {
        try {
            setCreateChapterTemplateDialogExecuting(true);
            createChapterTemplate();
            setCreateChapterTemplateDialogExecuting(false);
            setCreateChapterTemplateDialog(false);
            setChapterName("");
        }
        catch {

        }
    }, [setCreateChapterTemplateDialogExecuting, createChapterTemplate, setCreateChapterTemplateDialog, setParentAlert, getParentData]);

    const nameHandler = useCallback((event) => {
        setChapterName(event.target.value);
    }, []);
    const selectCollectionHandler = (event) => {
        setCurrentCollection(event.target.value);
    };
    
    // Handler for adding the selected collection to the selected list
    const addCollectionButtonHandler = () => {
        const selectedItem = collectionList.find(item => item._id === currentCollection);
        if (selectedItem) {
            setSelectedCollectionList([...selectedCollectionList, selectedItem]);
            setCurrentCollection(""); // Reset current selection after adding
        }
    };
    // Hooks ===
    const removeCollectionButtonHandler = useCallback((item) => {
        let tempList = selectedCollectionList;

        tempList.splice(selectedCollectionList.findIndex(oldItem => oldItem._id == item._id), 1);

        setSelectedCollectionList([...tempList]);

    }, [selectedCollectionList, setSelectedCollectionList]);

    // Render Section ===

    return (
        <>
            {createChapterTemplateDialog ? (
                <Dialog id="create-chapter-template-dialog"
                    fullWidth
                    maxWidth="md"
                    open={createChapterTemplateDialog}
                    onClose={() => { closeHandler(); }}
                >
                    <DialogTitle>
                        Create Workbook
                    </DialogTitle>
                    <DialogContent>
                        {createChapterTemplateDialogExecuting ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <DialogContentText>
                                    Please enter a valid name for your new Workbook.
                                </DialogContentText>
                                <Box mx={1} my={1} boxShadow={0}>
                                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                                        <Grid item>
                                            <TextField label="Workbook Name"
                                                size="small"
                                                variant="filled"
                                                error={chapterName === "" ? true : false}
                                                fullWidth
                                                value={chapterName}
                                                onChange={(event) => { nameHandler(event); }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <DialogContentText>
                                    Please select a module name for your new Workbook.
                                </DialogContentText>
                                <Box mx={1} my={1} boxShadow={0}>
                                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                                        <Grid item xs container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                                            {collectionList ? (
                                                <>
                                                    <Grid item xs={11}>
                                                        <FormControl id="Collection-options-label" variant="filled" size="small" fullWidth disabled={!collectionList}>
                                                            <InputLabel>
                                                                Templates
                                                            </InputLabel>
                                                            <Select
                                                                fullWidth
                                                                labelId="Collection-options-label"
                                                                value={currentCollection}
                                                                onChange={(event) => { selectCollectionHandler(event); }}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {collectionList.map((item, index) => (
                                                                    <MenuItem
                                                                        key={`SelectOption${item._id}`}
                                                                        value={item._id}
                                                                        disabled={selectedCollectionList.some(oldItem => oldItem._id === item._id)}
                                                                    >
                                                                        <em>{item.name}</em>
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton
                                                            variant="outlined"
                                                            size="medium"
                                                            color="inherit"
                                                            onClick={() => { addCollectionButtonHandler(); }}
                                                            disabled={!currentCollection}
                                                        >
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <CircularProgress />
                                            )}
                                        </Grid>
                                        <Grid item xs>
                                            {(selectedCollectionList) ? (
                                                <Collapse in={(selectedCollectionList.length > 0) ? true : false}>
                                                    <Typography component="div" variant="body2" color="textSecondary" gutterBottom={true}>
                                                        <em>{"The following selected collections"}</em> <u>{'to be assigned:'}</u>
                                                    </Typography>
                                                    <Typography component="div" variant="body2" color="primary" gutterBottom={true}>
                                                        <ol>
                                                            {selectedCollectionList.map((item, index) => {
                                                                return (
                                                                    <li key={`${item._id}${index}`}>
                                                                        {item.name}
                                                                        <IconButton aria-label="delete" className={classes.margin} size="small"
                                                                            onClick={(item) => { removeCollectionButtonHandler(item); }}
                                                                        >
                                                                            <CancelIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ol>
                                                    </Typography>
                                                </Collapse>
                                            ) : (
                                                <>
                                                </>
                                            )}
                                        </Grid>
                                      
                                    </Grid>
                                </Box>
                                {/* <Box mx={1} my={1} boxShadow={0}>
                                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                                        <Grid item>
                                            <TextField label="Workbook Name"
                                                size="small"
                                                variant="filled"
                                                error={chapterName === "" ? true : false}
                                                fullWidth
                                                value={chapterName}
                                                onChange={(event) => { nameHandler(event); }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box> */}

                            </>

                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={() => { closeHandler(); }} disabled={createChapterTemplateDialogExecuting}>
                            Cancel
                        </Button>
                        <Button color="primary" variant="contained" startIcon={<AddBoxOutlinedIcon />} onClick={() => { createHandler(); }} disabled={createChapterTemplateDialogExecuting || chapterName === "" ? true : false}>
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                null
            )}
        </>

    );
}

// ======================== Component PropType Check ========================
CreateChapterTemplateDialog.propTypes =
{
    // You can specify the props types in object style with ___.PropTypes.string.isRequired etc...
    appState: PropTypes.object.isRequired,
    setParentAlert: PropTypes.func.isRequired,
    getParentData: PropTypes.func.isRequired,
    createChapterTemplateDialog: PropTypes.bool.isRequired,
    setCreateChapterTemplateDialog: PropTypes.func.isRequired,
    createChapterTemplateDialogExecuting: PropTypes.bool.isRequired,
    setCreateChapterTemplateDialogExecuting: PropTypes.func.isRequired

}

CreateChapterTemplateDialog.defaultProps =
{
    appState: {},
    setParentAlert: () => { },
    getParentData: () => { },
    setCreateChapterTemplateDialog: () => { },
    setCreateChapterTemplateDialogExecuting: () => { }
}

export default CreateChapterTemplateDialog;  // You can even shorthand this line by adding this at the function [Component] declaration stage